import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import Container from '../../layout/Container';
import ParallaxFixDiv from '../Parallax';

import UseDimension from '../../uses/useDimension';
import UseWindowSize from '../../uses/useWindowSize';

import { ISingleImage } from './interfaces';

import { ReactComponent as IconHand } from './icon-hand.svg';

import './styles.scss';

// image full
const SingleImage: FC<ISingleImage> = ({
  value: {
    image: {
      original
    }
  }
}) => {
  const [ size, setSize ] = useState<boolean>(false);

  const { width } = UseWindowSize();

  const div = useRef<any>(null);
  const image = useRef<any>(null);

  const divProps = UseDimension(div);
  const imgProps = UseDimension(image);

  // on load
  const onLoad = useCallback(() => {
    if (divProps.width < imgProps.width) {
      setSize(true);
    }
  }, [ setSize, divProps, imgProps ]);

  // use effect
  useEffect(() => {
    if (width) {
      onLoad();
    }
  }, [ width, onLoad ]);

  // render
  return (
    <div
      className="single-image"
      ref={div}
      data-size={size}>
      <ParallaxFixDiv y={[ 5, -5 ]}>
        <Container>
          <div className="container" >
            <img
              height={original?.height}
              width={original?.width}
              src={original?.src}
              onLoad={onLoad}
              alt=""
              ref={image} />
          </div>
        </Container>
      </ParallaxFixDiv>

      {size === true && <div className="single-image--icon">
          <IconHand />
        </div>}
    </div>
  );
};

export default SingleImage;