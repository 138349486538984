import React, { FC } from "react";
import Container from "../../layout/Container";
import { ITable } from "./interfaces";

import "./styles.scss";

// table
const Table: FC<ITable> = ({
  value: { data, first_row_is_table_header, first_col_is_header, table_caption },
}) => {
  const getTable = (data: any[], isHeaderRow: boolean, isHeaderCol: boolean, caption?: string) => {
    if (!data.length) return null;

    const table = document.createElement("table");
    const thead = document.createElement("thead");
    const tbody = document.createElement("tbody");
    const tcaption = document.createElement("caption");

    if (caption) {
      tcaption.textContent = caption;
      table.appendChild(tcaption);
    }

    data.forEach((row: string[]) => {
      const tr = document.createElement("tr");

      row.forEach((col: string) => {
        const td = document.createElement("td");
        td.textContent = col;
        tr.appendChild(td);
      })

      if (row === data[0]) {
        thead.appendChild(tr);
      } else {
        tbody.appendChild(tr);
      }
    });

    if (isHeaderRow) {
      const newHead = thead.innerHTML.replaceAll("<td>", "<th>").replaceAll("</td>", "</th>")
      thead.innerHTML = newHead;

      table.appendChild(thead);
      table.appendChild(tbody);
    }
    else {
      table.appendChild(thead);
      table.appendChild(tbody);
    }

    if (isHeaderCol) {
      const rows = table.querySelectorAll("tr");
      
      rows.forEach((row) => {
        const firstCol = row.querySelector("th, td");

        if (firstCol?.tagName === 'TD') {
          const newFirstCol = firstCol.outerHTML.replace("<td>", "<th>").replace("</td>", "</th>");
          firstCol.outerHTML = newFirstCol;
        }
      })
    }
    
    return table;
  }

  // render
  return (
    <div className="table">
      <Container type={2}>
        {getTable(data, first_row_is_table_header, first_col_is_header, table_caption)?.outerHTML && (
          <div dangerouslySetInnerHTML={{ __html: getTable(data, first_row_is_table_header, first_col_is_header, table_caption)!.outerHTML }} />
        )}
      </Container>
    </div>
  );
};

export default Table;
