import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import PageSearchInput from '../PageSearchInput';

import Container from '../../../layout/Container';

import { IPageSearchForm } from './interfaces';

import './styles.scss';

// page search form
const PageSearchForm: FC<IPageSearchForm> = ({
  lang,
  value,
  onChange
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="page-search-form">
      <Container type={2}>
        <div className="page-search-form--title">
          <h1 className="h1">{t('search.title')}</h1>
        </div>

        <PageSearchInput
          lang={lang}
          value={value}
          onSearch={onChange} />
      </Container>
    </div>
  );
};

export default PageSearchForm;