import React, { FC } from 'react';

import HeaderTopMenu from '../HeaderTopMenu';
import LangSelect from '../../../../components/LangSelect';

import { IHeaderTopSubmenuMobile } from './interfaces';

import './styles.scss';

// header top submenu
const HeaderTopSubmenuMobile: FC<IHeaderTopSubmenuMobile> = ({ 
  routes,
  lang
 }) => {
  // render
  return (
    <div
      className="header-top-submenu-mobile">
      <HeaderTopMenu
        lang={lang}
        items={routes.filter((item: any) => item.navegacao === 'secundaria')} />

      <div className="header-top-submenu-contact">
        <span>Emergências / Informações: 0800 098 88 55</span>
      </div>
    </div>
  );
};

export default HeaderTopSubmenuMobile;