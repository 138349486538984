// icon bio
const IconBio = () => {
  return (
    <svg width="44" height="45" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_302_6)">
        <path d="M10.1058 31.8184L42.3944 43.0983L35.004 13.0962C33.6743 9.00262 30.7068 5.63647 26.6699 3.62661C22.6222 1.61137 17.9561 1.18685 13.5865 2.45436L13.5822 2.45558C9.17984 3.71338 5.5678 6.49896 3.40517 10.2631C1.24234 14.0276 0.756242 18.3692 2.07894 22.4769L2.5753 24.0183C4.10931 27.3861 6.73304 30.1235 10.1058 31.8184Z" fill="white" stroke="#459AD9" strokeWidth="2"/>
        <g clipPath="url(#clip1_302_6)">
          <path d="M19 13V23" stroke="#459AD9" strokeWidth="3" strokeLinecap="round"/>
          <path d="M24 18H14" stroke="#459AD9" strokeWidth="3" strokeLinecap="round"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_302_6">
          <rect width="44" height="45" fill="white"/>
        </clipPath>
        <clipPath id="clip1_302_6">
          <rect width="14" height="14" fill="white" transform="translate(12 11)"/>
        </clipPath>
      </defs>
    </svg>
  )
};

export default IconBio;