import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Pagination from '../../../../components/Pagination';
import PageSearchResultItem from '../PageSearchResultItem';

import { IPageSearchResultPagination } from './interfaces';

import './styles.scss';

// page search result pagination
const PageSearchResultPagination: FC<IPageSearchResultPagination> = ({
  data,
  lang,
  page,
  onNextPrev,
  perPage,
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="page-search-result-pagination">
      <div className="page-search-result-pagination--title">
        <p className="text">
          <span className="number">{data?.meta.total_count}</span>
          <span className="text">{data?.meta.total_count > 1 ? t('search.results.find') : t('search.result.find')}</span>
        </p>
      </div>

      <div
        className="page-search-result-pagination">
        {data?.items && data?.items.map((item: any, index: number) =>
          <PageSearchResultItem
            {...item}
            lang={lang}
            key={index} />)}
      </div>
      
      {data?.meta && data?.meta.total_count > 0 &&
        <Pagination
          current={page}
          total={Math.ceil(data?.meta.total_count / perPage)}
          setCurrent={onNextPrev} />}
    </div>
  );
};

export default PageSearchResultPagination;