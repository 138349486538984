import React, { FC } from 'react';

import Text from '../../Typography/Text';

import { ICardImageTitleTextItem } from './interfaces';

import './styles.scss';

// card business item
const CardImageTitleTextItem: FC<ICardImageTitleTextItem> = ({
  image,
  image_alt,
  title,
  description
}) => {
  return (
    <div
      className="card-image-title-text-item">
      <img
        className="image"
        src={image?.original.src}
        alt={image_alt} />
      
      <strong className="card-image-title-text-item-title">{title}</strong>

      <Text attrs={{ className: 'card-image-title-text-item-description' }} type="danger" text={description} />
    </div>
  );
};

export default CardImageTitleTextItem;