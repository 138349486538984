import React, { FC, useState } from 'react';
import Slider from 'react-slick';

import Container from '../../layout/Container';
import MultimediaGallerySliderArrow from './MultimediaGallerySliderArrow';
import MultimediaGallerySliderItem from './MultimediaGallerySliderItem';

import { IMultimediaGallerySlider } from './interfaces';

import { settings } from './settings';

import './styles.scss';

// list carrousel multimedia
const MultimediaGallerySlider: FC<IMultimediaGallerySlider> = ({ 
  value
}) => {
  const [ slick, setSlick ] = useState<any>({ current: 0, last: null });

  // config
  const configSettings = {
    ...settings,
    beforeChange: (current: number, next: number) => setSlick({ last: current, current: next }),
    afterChange: (current: number) => setSlick({ ...slick, next: current })
  };

  // render
  return (
    <div
      className="multimedia-gallery-slider">
      <Container>
        {value && <Slider
            className="slider"
            nextArrow={<MultimediaGallerySliderArrow type="next" />}
            prevArrow={<MultimediaGallerySliderArrow type="prev" />}
            {...configSettings}>
            {value && value.map((item: any, index: number) =>
              <MultimediaGallerySliderItem
                {...item}
                current={slick}
                index={index}
                key={index} />)} 
          </Slider>}
      </Container>
    </div>
  );
};

export default MultimediaGallerySlider;