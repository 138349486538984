import React, { FC, useCallback } from 'react';

import Container from '../../layout/Container';

import { LinkButton } from '../LinkButton';
import { IButton } from './interfaces';

import './styles.scss';

// button
const Button: FC<IButton> = ({
  lang,
  value: {
    align,
    label,
    external_link,
    internal_link,
  }
}) => {
  const factoryLink = useCallback((url: string) => {
    const src: string = url.replace(/.*\/\/[^/]*/, '');

    return `/${lang}${src.slice(0, -1)}`;
  }, [ lang ]);

  // render
  return (
    <div className='button'>
      {external_link && !internal_link 
        ?
        <LinkButton href={external_link} target="_blank" align={align} children={label} />
        :
        <LinkButton href={factoryLink(internal_link.url)} align={align} children={label} />
      }
    </div>
  );
};

export default Button;