import React, { FC } from 'react';

import ParallaxFixDiv from '../Parallax';
import Text from '../Typography/Text';

import { IInformationCard } from './interfaces';

import './styles.scss';

const InformationCard: FC<IInformationCard> = ({
  value: {
    title,
    name,
    contacts_list,
  }
}) => {
  // render
  return (
    <>
      <div  className="information-card">
        <span className="title">{title}</span>
        
        <ul className="info-list">
          <li className="info-name">{name}</li>

          {contacts_list.map((contact: any, index: number) => (
            <li className={contact.type === "phone" ? "info-phone" : "info-email"} key={index}>
              <span>{contact.type === "phone" ? "Telefone: " : "E-mail: "}</span>

              <a href={contact.type === "phone" ? `tel:${contact.value}` : `mailto:${contact.value}`}>
                {contact.value}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default InformationCard;