import React, { FC } from 'react';

import SliderArrow from '../../../assets/icons/slider-arrow';

import { INewsSliderControl } from './interfaces';

import './styles.scss';

// list news control
const NewsSliderControl: FC<INewsSliderControl> = ({
  currentSlide = 0,
  onClick,
  slideCount = 0,
  type,
}) => {
  // render
  return (
    <button
      className={`btn news-slider-control-button ${type}`}
      data-disabled={type === 'left' ? currentSlide <= 0 : currentSlide >= (slideCount - 1) || !onClick}
      onClick={onClick}>
      <SliderArrow />
    </button>
  );
};

export default NewsSliderControl;