import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

import Container from '../../Container';
import HeaderTopLogo from './HeaderTopLogo';
import HeaderTopSubmenu from './HeaderTopSubmenu';

import { IHeaderTop } from './interfaces';

import './styles.scss';

// header top
const HeaderTop: FC<IHeaderTop> = ({
  lang,
  min,
  open,
  routes,
  setOpen,
  queries,
  children
}) => {
  const { pathname } = useLocation();
  const onMenu = useRef<boolean>(false);

  // close change url
  const onChangeLocation = useCallback(() =>
    setOpen(false), [ setOpen ]);

  // 
  useEffect(() => { onMenu.current = open });
  
  // use effect
  useEffect(() => {
    if (onMenu.current === true) {
      onChangeLocation();
    }
  }, [ pathname, onChangeLocation, onMenu ]);

  // render
  return (
    <div
      className="header-top"
      data-min={min}>
      <Container>
        <HeaderTopLogo
          min={min} />

        <nav className="header-navigation">
          <Container>
            <HeaderTopSubmenu
              lang={lang}
              open={open}
              queries={queries}
              routes={routes}
              setOpen={setOpen} />

            {children}
          </Container>
        </nav>
      </Container>
    </div>
  );
};

export default HeaderTop;