//@ts-nocheck
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Text from '../../../Typography/Text';

import { IWebdoorInfo } from './interfaces';

import './styles.scss';

// webdoor info
const WebdoorInfo: FC<IWebdoorInfo> = ({
  active,
  factoryLink,
  item
}) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="webdoor-info"
      data-active={active}>
      
      <Text attrs={{ className: 'h2' }} type="danger" text={item?.value.title} />

      {item.value.internal_link && <Link
        to={factoryLink(item?.value.internal_link?.url)}
        className="webdoor-info--btn-link">
          {t('more')}
      </Link>}
    </div>
  );
};

export default WebdoorInfo;