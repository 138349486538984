import React, { memo, FC, useCallback, useEffect, useState } from 'react';

import ReactPlayer from 'react-player';

import { IWebdoor } from './interfaces';

import './styles.scss';

// Webdoor item
const Webdoor: FC<IWebdoor> = ({
  active,
  dir,
  drag,
  item,
  factoryLink,
  last,
}) => {

  useEffect(() => {
    const webdoorList = document.querySelectorAll(".webdoor");

    const pauseAllVideos = () => {
      const videos = document.querySelectorAll(".webdoor video");

      videos.forEach((video: any) => {
        if (video.currentTime > 0 && !video.paused) {
          video.pause();
          video.currentTime = 0;
        }
      })
    }

    webdoorList.forEach((item) => {
      const handleVideo = () => {  
        if (item.getAttribute("data-active") === "true") {
          const video = item.querySelector("video");

          if (video && video.paused) {
            video.play();
          }
        }
      }

      handleVideo();

      const observer = new MutationObserver(() => {
        pauseAllVideos();
        handleVideo();
      });

      observer.observe(item, { attributes: true, childList: true, subtree: true });
    })
  }, [])

  // on drag
  const onDrag = useCallback(() => {
    if (typeof drag === 'function') return drag();

    return {};
  }, [ drag ]);
  
  // render
  return (
    <div
      className="webdoor"
      {...onDrag()}
      data-active={active}
      data-last={last}
      data-dir={dir}>
        <div className="link">
          { item?.type === 'webdoor_image_item' 
            ? 
            <img src={item?.value.image.original.src} alt="webdoor" />
            :
            <ReactPlayer            
              width='100%'
              height='100%'
              loop={true}
              playing={false}
              muted={true}
              url={item?.value.video.src}
            />
          }
        </div>
    </div>
  );
};

export default memo(Webdoor);