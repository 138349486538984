import React, { FC, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import Text from '../../Typography/Text';
import UseNavigation from '../../../uses/useNavigation';

import { INewsSliderItem } from './interfaces';

import './styles.scss';

// list news item
const NewsSliderItem: FC<INewsSliderItem> = ({
  meta,
  lang,
  title,
  image,
  date,
  summary,
  getDate
}) => {
  const { getNewsPrefix }: any = UseNavigation();
  const [limitedTitle, setLimitedTitle] = useState(title);
  const [limitedSummary, setLimitedSummary] = useState(summary);

  // use effect
  useEffect(() => {
    const charLimiter = () => {
      if (title) {
        if (title.length > 37) {
          setLimitedTitle(title.substring(0, 37) + '...');
        }
      }
  
      if (summary) {
        if (summary.length > 135) {
          setLimitedSummary(summary.substring(0, 135) + '...');
        }
      }
    }
    
    charLimiter()
  }, [title, summary]);

  // get image
  const getImage = useCallback(() => {
    if (image instanceof Object) {
      return image.meta.download_url;
    }

    return `${process.env.PUBLIC_URL}/images/placeholder-news.png`;
  }, [ image ]);

  // render
  return (
    <div
      className="news-slider-item">
      <Link
        className="link"
        to={`${getNewsPrefix(lang?.lang)}/${meta?.slug}`}>
        <div className="news-slider-item--info">
          <img
            alt={title}
            src={getImage()} />
            {date &&
              <p className="date">
                {getDate(date, 'dd/MM/yyyy')}
              </p>
            }
          <Text attrs={{ className: 'description' }} text={limitedSummary} type="danger" />
          <span>Saiba mais</span>
        </div>
      </Link>
    </div>
  );
};

export default NewsSliderItem;