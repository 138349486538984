import AccordionList from './AccordionList';
import TextBanner from './TextBanner';
import SingleImage from './SingleImage';
import ImageTextList from './ImageTextList';
import ImageSideBySide from './ImageSideBySide';
import FormContact from './Form/FormContact';
import ImageTextSlider from './ImageTextSlider';
import ListCarrouselBusinessPhotoAlbum from './List/ListCarrouselBusinessPhotoAlbum';
import MultimediaGallerySlider from './MultimediaGallerySlider';
import NewsSlider from './NewsSlider';
import NewsList from './NewsList';
import BoardManagement from './BoardManagement';
import Timeline from './Timeline';
import TitleText from './TitleText';
import WebdoorList from './WebdoorList';
import SingleVideo from './SingleVideo';
import FilesList from './FilesList';
import RelatedContentList from './RelatedContentList';
import TwoColumns from './TwoColumns';
import Button from './Button';
import CardImageTitleText from './CardImageTitleText';
import InformationCard from './InformationCard';
import Table from './Table';
import HighlightBannerWithIcon from './HighlightBannerWithIcon';

export const TYPE_COMPONENTS = [
  {
    type: "title_text",
    componentElement: TitleText,
  }, {
    type: "webdoor_list",
    componentElement: WebdoorList,
  }, {
    type: "image_text_list",
    componentElement: ImageTextList
  }, {
    type: "two_images_cards",
    componentElement: ImageSideBySide
  }, {
    type: "single_video",
    componentElement: SingleVideo
  }, {
    type: "text_banner",
    componentElement: TextBanner
  }, {
    type: "image_text_slider",
    componentElement: ImageTextSlider
  }, {
    type: "multimedia_gallery_slider",
    componentElement: MultimediaGallerySlider
  }, {
    type: "cards_slider",
    componentElement: ListCarrouselBusinessPhotoAlbum
  }, {
    type: "single_image",
    componentElement: SingleImage
  }, {
    type: "accordion_list",
    componentElement: AccordionList
  }, {
    type: "files_list",
    componentElement: FilesList
  }, {
    type: "board_management",
    componentElement: BoardManagement
  }, {
    type: "timeline",
    componentElement: Timeline
  }, {
    type: "news_list",
    componentElement: NewsList
  }, {
    type: "news_slider",
    componentElement: NewsSlider
  }, {
    type: "form",
    componentElement: FormContact
  }, {
    type: "related_content_list",
    componentElement: RelatedContentList
  }, {
    type: "two_columns",
    componentElement: TwoColumns
  }, {
    type: "button",
    componentElement: Button
  }, {
    type: "card_image_title_text",
    componentElement: CardImageTitleText
  }, {
    type: "card_informations",
    componentElement: InformationCard
  }, {
    type: "table",
    componentElement: Table
  } , {
    type: "highlight_banner_with_icons",
    componentElement: HighlightBannerWithIcon
  }
];