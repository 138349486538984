import React, { FC } from 'react';

import SliderArrow from '../../../assets/icons/slider-arrow';

import { IMultimediaGallerySliderArrow } from './interfaces';

import './styles.scss';

// list carrousel multimedia arrow
const MultimediaGallerySliderArrow: FC<IMultimediaGallerySliderArrow> = ({
  currentSlide = 0,
  slideCount = 0,
  onClick,
  type
}) => {
  return (
    <button
      className="btn multimedia-gallery-slider-arrow"
      data-type={type}
      data-disabled={type === 'prev' ? currentSlide <= 0 : currentSlide >= (slideCount - 1)}
      onClick={onClick}>
      <SliderArrow />
    </button>
  );
};

export default MultimediaGallerySliderArrow;