import React, { FC } from "react";

import ColumnItem from "./ColumnItem";
import { IColumn } from "./interfaces";

import "./styles.scss";

const Column: FC<IColumn> = ({
  lang,
  column,
  dataColumn
}) => {
  // render
  return (
    <>
      {column && column.map((item: any, index: number) => (
        <ColumnItem {...item} key={index} lang={lang} dataColumn={dataColumn} />
      ))}
    </>
  );
};

export default Column;