import React, { FC } from "react";

import Container from "../../layout/Container";
import Column from "./Column";

import { ITwoColumns } from "./interfaces";

import "./styles.scss";

const TwoColumns: FC<ITwoColumns> = ({
  lang,
  value: {
    title,
    column_1,
    column_2
  }
}) => {
  // render
  return (
    <div className="two-columns">
      {title && <h3 className="h3">{title}</h3>}

      <Container>
        <div className="column-container">
          {column_1 && <Column column={column_1} lang={lang} dataColumn="1" />}
          {column_2 && <Column column={column_2} lang={lang} dataColumn="2" />}
        </div>
      </Container>
    </div>
  );
};

export default TwoColumns;