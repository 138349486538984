import React, { FC } from 'react';

import SliderArrow from '../../../../assets/icons/slider-arrow';

import { IImageTextSliderControlsButton } from './interfaces';

import './styles.scss';

// list business controls button
const ImageTextSliderControlsButton: FC<IImageTextSliderControlsButton> = ({
  disabled,
  onClick,
  type
}) => {
  return (
    <button
      className="btn image-text-slider--controls--button"
      disabled={disabled}
      data-type={type}
      onClick={() => onClick(type)}
    >
      <SliderArrow />
    </button>
  );
};

export default ImageTextSliderControlsButton;