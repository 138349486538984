//@ts-nocheck
import React, { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '../../../../components/Icon';

import { IHeaderTopMenu } from './interfaces';

import IconEmail from '../../../../assets/icons/email';

import './styles.scss';

// header top submenu
const HeaderTopMenu: FC<IHeaderTopMenu> = ({
  items,
  lang,
  queries,
}) => {
  const { t }: any = useTranslation();

  // get icon
  const checkIcon = useCallback((slug: string) =>
    slug === t('contact').toString().toLowerCase(), [ t ]);

  // mobile contact icon
  const MobileContactIcon = useCallback((slug: string) => {
    if (checkIcon(slug)) {
      return <IconEmail />;
    }

    return '';
  }, [ checkIcon ]);

  // render
  return (
    <div
      className="header-top-menu">
      {items && items.map(({ meta: { slug }, title, url_externa }, index: number) =>
          <li
            className="header-top-menu--item"
            key={index}>
            {!url_externa 
            ?
            <Link
              className="header-top-menu--item--link"
              data-icon={checkIcon(slug)}
              to={`/${lang}/${slug}`}>
              {(queries !== 'lg' && queries !== 'md') && MobileContactIcon(slug)}

              <span className="paragraph light">{title}</span>

              {(queries !== 'lg' && queries !== 'md') &&
                <Icon icon="icon-arrow-right" />}
            </Link>
            :
            <a 
              href={url_externa} 
              className="header-top-menu--item--link" 
              data-icon={checkIcon(slug)}
              target="_blank">
              {(queries !== 'lg' && queries !== 'md') && MobileContactIcon(slug)}

              <span className="paragraph light">{title}</span>

              {(queries !== 'lg' && queries !== 'md') &&
                <Icon icon="icon-arrow-right" />}
            </a>
            }
          </li>)}
    </div>
  );
};

export default HeaderTopMenu;