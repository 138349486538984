import { FC, useCallback } from 'react';

import { IText } from './interfaces';

// Text
const Text: FC<IText> = ({ attrs, children, text, type }) => {
  // type
  const getType = useCallback(() => {
    switch (type) {
      case 'danger':
        return <p {...attrs} className={`paragraph ${attrs?.className}`} dangerouslySetInnerHTML={{ __html: text }} />;
      case '':
      default:
        return <p {...attrs} className={`paragraph ${attrs?.className}`}>{children}</p>;
    }
  }, [ type, attrs, text, children ]);

  // render
  return (<>{getType()}</>);
};

export default Text;