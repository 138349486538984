import React, { FC, useContext, useState } from 'react';

import HeaderMenuMobile from './HeaderMenuMobile';
import HeaderMenu from './HeaderMenu';
import HeaderTop from './HeaderTop';
import withMediaQueries from '../../components/MediaQueries';

import { NavContext } from '../../providers/NavProvider';
import { LangContext } from '../../providers/LangProvider';

import useNavigation from '../../uses/useNavigation';

import { IHeader } from './interfaces';
import { INavContext } from '../../providers/NavProvider/interfaces';

import './styles.scss';

// header
const Header: FC<IHeader> = ({
  type,
  queries
}) => {
  const { lang }: any = useContext(LangContext);
  const { routes }: INavContext = useContext(NavContext);

  const [ open, setOpen ]: any = useState<boolean>(false);

  const { getMenuMain } = useNavigation();

  // render
  return (
    <header
      className="header"
      data-open={open}
      data-min={type === 3 || false}
      data-type={type}>
      
      {routes &&
        <>
          <HeaderTop
            lang={lang?.lang}
            min={false}
            queries={queries} 
            open={open}
            routes={routes}
            setOpen={setOpen}>

            {(queries === 'lg' || queries === 'md') &&
            <HeaderMenu
              lang={lang?.lang}
              min={false}
              routes={getMenuMain(routes)}
              type={type} />}

            {(queries !== 'lg' && queries !== 'md') &&
            <HeaderMenuMobile
              open={open}
              lang={lang?.lang}
              routes={routes}
              mainMenu={getMenuMain(routes)}
              setOpen={setOpen} />}
          </HeaderTop>
        </>}
    </header>
  );
};

export default withMediaQueries(Header);