import React, { FC } from 'react';

import TimelineItemSliderButton from '../TimelineItemSliderButton';

import { ITimelineItemSliderControls } from './interfaces';

import './styles.scss';

// time line item slider controls
const TimelineItemSliderControls: FC<ITimelineItemSliderControls> = ({
  current,
  count,
  onPrevNext
}) => {
  return (
    <div
        className="time-line-item-slider-controls">
        <TimelineItemSliderButton
          type="prev"
          current={current}
          count={count}
          onClick={() => onPrevNext('prev')} />

        <TimelineItemSliderButton
          type="next"
          current={current}
          count={count}
          onClick={() => onPrevNext('next')} />
      </div>
  );
};

export default TimelineItemSliderControls;