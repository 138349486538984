import React, { FC } from 'react';

import { IListCarrouselBusinessPhotoAlbumButton } from './interfaces';

import './styles.scss';

// list carrousel business photo album button
const ListCarrouselBusinessPhotoAlbumButton: FC<IListCarrouselBusinessPhotoAlbumButton> = ({
  onClick = () => {},
  currentSlide = 0,
  slideCount = 0,
  type
}) => {
  return (
    <button
      className={`btn list-carrousel-business-photo-album-button ${type}`}
      data-disabled={type === 'left' ? currentSlide <= 0 : currentSlide >= (slideCount - 1) || !onClick}
      onClick={onClick}>
    </button>
  );
};

export default ListCarrouselBusinessPhotoAlbumButton;