import React, { FC } from 'react';

import Container from '../../layout/Container';
import { LinkButton } from '../LinkButton';

import ParallaxFixDiv from '../Parallax';

import { ITextBanner } from './interfaces';

import './styles.scss';
import IconBackground from '../../assets/icons/background';

// call to action
const TextBanner: FC<ITextBanner> = ({
  value: {
    button_label,
    external_link,
    description,
    title
  }
}) => {
  return (
    <div
      className="text-banner">
      <Container>
        <p className="main-title">{title}</p>
        <p className="title description">{description}</p>
        
        {button_label && external_link &&
        <LinkButton href={external_link} target='_blank' rel='noopener' align='left'>
          {button_label}
        </LinkButton>
        }

        <div className="icon-large">
          <ParallaxFixDiv y={[ -20, 20 ]}>
            <IconBackground />
          </ParallaxFixDiv>
        </div>
      </Container>
    </div>
  );
};

export default TextBanner;