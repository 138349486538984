import React, { FC, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import ParallaxPageProvider from '../../providers/ParallaxPage';
import Page from '../../layout/Page';

import UseNavigation from '../../uses/useNavigation';
import UsePage from '../../uses/usePage';

import { IHome } from './interfaces';

import './styles.scss';

// base api
const { REACT_APP_BASE_API }: any = process.env;

// home
const Home: FC<IHome> = ({
  lang
}) => {
  const { getUrl } = UseNavigation();
  const [ data, setData ] = useState<any>(null);

  const url: string = getUrl(lang?.lang, REACT_APP_BASE_API);

  // use effect
  useEffect(() => {
    const page = async () =>
      await UsePage(url)
      .then((data) => setData(data));

    trackPromise(page());
  }, [ setData, lang, url ]);

  // render
  return (
    <div className='home'>
      <Header />

      <ParallaxPageProvider>
        <Page
          data={data?.page}
          lang={lang?.lang} />
      </ParallaxPageProvider>

      <Footer lang={lang} />
    </div>
  );
};

export default Home;