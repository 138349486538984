import React, { FC } from "react";

import Container from "../../layout/Container";
import File from "./File";

import { IFilesList } from "./interfaces";

import "./styles.scss";

// list files
const FilesList: FC<IFilesList> = ({ value }) => {
  return (
    <div className="files-list">
      <Container>
        {value &&
          value.map((item: any, index: number) => (
            <File {...item} key={index} />
          ))}
      </Container>
    </div>
  );
};

export default FilesList;