import React, { FC } from 'react';

import { IHeroImage } from './interfaces';

import './styles.scss';

// webdoor internal
const HeroImage: FC<IHeroImage> = ({
  image,
  title
}) => {
  return (
    <div
      className="hero-image">
      {image &&
        <img
          className="image"
          src={image?.meta.download_url}
          alt={title} />}

      <h2 className="h2">{title}</h2>
    </div>
  );
};

export default HeroImage;