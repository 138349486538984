import React, { FC, useCallback, useRef } from 'react';
import clamp from 'lodash/clamp';
import { useGesture } from 'react-use-gesture';

import ImageTextSliderImageItem from './ImageTextSliderImageItem';

import { IImageTextSliderImage } from './interfaces';

import './styles.scss';

// list business slider
const ImageTextSliderImage: FC<IImageTextSliderImage> = ({
  current,
  direction,
  last,
  items,
  setCurrent
}) => {
  const element = useRef<any>(null); // element ref

  // drag on gesture
  const dragOnGesture = useCallback(({
    down,
    movement: [mx],
    direction: [xDir],
    distance,
    cancel
  }: any) => {
    if (down && distance > 15) {
      const value = clamp(current + (xDir > 0 ? 1 : -1), 0, items.length - 1);
      cancel(setCurrent(value));
    }
  }, [ current, items, setCurrent ]);

  // drag
  const drag = useGesture({
    onDrag: dragOnGesture,
  });

  // render
  return (
    <>
      <div
        className="image-text-slider--image"
        ref={element}>
        {items && items.map((item: any, index: number) => (
          <ImageTextSliderImageItem
            active={index === current}
            last={index === last}
            drag={drag}
            dir={direction}
            item={item}
            type={1}
            key={index} />))}
      </div>
    </>
  );
};

export default ImageTextSliderImage;