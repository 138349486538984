import React, { FC, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';

import PageContainer from './PageContainer';
import PageNews from './PageNews';
import PageNotFound from '../PageNotFound';

import UseNavigation from '../../uses/useNavigation';
import UsePage from '../../uses/usePage';

import { IPageDefault } from './interfaces';

// base api
const { REACT_APP_BASE_API }: any = process.env;

// page default
const PageDefault: FC<IPageDefault> = ({
  lang,
  location
}) => {
  const { getUrl } = UseNavigation();
  const [ data, setData ] = useState<any>(null);

  const url: string = getUrl(lang?.lang, REACT_APP_BASE_API);
  // use effect
  useEffect(() => {
    const slug = location?.pathname.split(`/`);

    const page = async () => await UsePage(url, true, slug[slug.length - 1])
      .then((data) => {
        setData(data)
      });

    trackPromise(page());

  }, [ url, location ]);

  // render
  return (
    <>
      {data?.page?.meta && data?.page?.meta.type === 'imprensa.Noticia'
        ? <PageNews
            {...data}
            lang={lang} />
        :
      !data
        ? <PageNotFound 
            lang={lang} />
        : <PageContainer
            {...data}
            lang={lang} />}
    </>
  );
};

export default PageDefault;