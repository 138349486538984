import React, { FC } from 'react';

import TimelineItemSliderItem from '../TimelineItemSliderItem';

import { ITimelineItemSliderContainer } from './interfaces';

import './styles.scss';

// time line item slider container
const TimelineItemSliderContainer: FC<ITimelineItemSliderContainer> = ({
  current,
  direction,
  last,
  items,
}) => {
  // render
  return (
    <div
      className="time-line-item-slider-container">
      {items && items.map((item: any, index: number) => (
        <TimelineItemSliderItem
          active={current === index}
          dir={direction}
          last={last === index}
          item={item}
          key={index} />))}
    </div>
  );
};

export default TimelineItemSliderContainer;