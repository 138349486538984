import { FC } from 'react';

import { IIcon } from './interfaces';

import './styles.scss';

// icon
const Icon: FC<IIcon> = ({ className = '', icon }) => {
  return (<i className={`icon ${icon} ${className}`} />);
};

export default Icon;