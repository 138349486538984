import React, { FC } from 'react';

import BoardManagementItem from './BoardManagementItem';

import { IBoardManagement } from './interfaces';

import './styles.scss';

// profile list
const BoardManagement: FC<IBoardManagement> = ({
  value
}) => {
  // render
  return (
    <div
      className="board-management"
      data-center={value.length < 3}>
      {value && value.map((item: any, index: number) => 
        <BoardManagementItem {...item} key={index} />)}
    </div>
  );
};

export default BoardManagement;