import React, { FC } from 'react';

import ParallaxFixDiv from '../Parallax';
import Text from '../Typography/Text';

import { ICard } from './interfaces';

import './styles.scss';

// card
const Card: FC<ICard> = ({
  icon,
  image,
  type,
  title
}) => {
  // render
  return (
    <div 
      className="card"
      data-type={type}>
      <img
        className="image"
        src={image?.original.src}
        alt={title} />

      <ParallaxFixDiv
        y={[ -5, 5 ]}>
        <div className="link">
          <span className="icon">
            <img
              height={icon?.original.height}
              width={icon?.original.width}
              src={icon?.original.src}
              alt={icon?.original.alt} />
          </span>
          <span className="text">
            <Text attrs={{ className: 'name' }}>{title}</Text>
          </span>
        </div>
      </ParallaxFixDiv>
    </div>
  );
};

export default Card;