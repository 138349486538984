import React, { FC, useState } from 'react';
import ReactPlayer from 'react-player';

import Container from '../../layout/Container';
import VideoButton from '../../assets/icons/video-button';

import { ISingleVideo } from './interfaces';

import './styles.scss';

// video
const SingleVideo: FC<ISingleVideo> = ({
  value: {
    image,
    url
  }
}) => {
  const [ pause, setPause ] = useState<boolean>(false);

  // render
  return (
    <div
      className="single-video"
      data-active={pause}>
      <Container>
        <div className="single-video--container">
          <div
            className="single-video--image"
            onClick={() => setPause(!pause)}>
            
            <VideoButton />

            <img
              className="single-video--image--img"
              src={image?.large.src}
              alt="" />
          </div>

          <ReactPlayer
            className="single-video--video"
            width="calc(100vw - 40px)"
            height="100vh"
            playing={pause}
            url={url}
            controls={true}
            onEnded={() => setPause(false)} />
        </div>
      </Container>
    </div>
  );
};

export default SingleVideo;