import React, { FC, useRef } from 'react';
import { withRouter } from 'react-router';

import ImageTextSliderContent from './ImageTextSliderContent';

import { IImageTextSlider } from './interfaces';

import './styles.scss';

// list business
const ImageTextSlider: FC<IImageTextSlider> = ({
  lang,
  value,
}) => {
  // element
  const element: any = useRef<any>(null);

  // render
  return (
    <div
      className="image-text-slider"
      ref={element}
    >
      <ImageTextSliderContent items={value} lang={lang} />
    </div>
  );
};

export default withRouter(ImageTextSlider);