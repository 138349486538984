import React, { FC, useCallback } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
// import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import UseNavigation from '../../uses/useNavigation';

import { parse, format } from 'date-fns';
import { ptBR, es, enUS } from 'date-fns/locale';

import Container from '../../layout/Container';
import NewsSliderControl from './NewsSliderControl';
import NewsSliderItem from './NewsSliderItem';

import { INewsSlider } from './interfaces';

import { getSettings } from './settings';

import './styles.scss';

// env
const { REACT_APP_BASE_API }: any = process.env;

// fetcher
const fetcher = (url: string) => axios.get(url).then(({ data }: any) => data);

// list news
const NewsSlider: FC<INewsSlider> = ({
  url,
  lang
}) => {
  const { getUrl } = UseNavigation();

  const server = getUrl(lang, REACT_APP_BASE_API);

  // const { t }: any = useTranslation();
  const { data }: any = 
    useSWR(`${server}/api/v2/pages/?type=imprensa.Noticia&fields=image,date,summary&limit=9&order=-date`, fetcher);

  // get locale
  const getLocale = useCallback(() => {
    switch (lang) {
      case 'es':
        return es;
      case 'en':
        return enUS;
      case 'pt':
        default:
          return ptBR
    }
  }, [ lang ]);

  // get date
  const getDate = useCallback((date: string, form: string) => {
    const value = format(parse(date, 'yyyy-MM-dd', new Date()), form, { 
      locale: getLocale()
    });

    return value;
  }, [ getLocale ]);

  // render
  return (
    <div className="news-slider">
      <Container>
        {Array.isArray(data?.items) &&
          <Slider
            {...getSettings(data.items.length)} // Passa o número total de slides
            className="slider"
            nextArrow={<NewsSliderControl type="right" />}
            prevArrow={<NewsSliderControl type="left" />}>
            {data.items.slice(0, 8).map((item: any, index: number) => (
              <NewsSliderItem
                {...item}
                getDate={getDate}
                lang={lang}
                key={index} />
            ))}
          </Slider>}
      </Container>
    </div>
  );
};

export default NewsSlider;