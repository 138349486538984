import React, { FC } from "react";

import { IImageSideBySideItem } from "./interface";

import "./styles.scss";

// image side by side item
const ImageSideBySideItem: FC<IImageSideBySideItem> = ({
  original: { src, alt },
}) => {
  // render
  return (
    <div className="image-side-by-side-item">
      <img src={src} alt={alt} />
    </div>
  );
};

export default ImageSideBySideItem;
