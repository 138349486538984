// use meta tags
function UseMetaTags(lang: string) {
  const getMetaContent = () => {
    switch (lang) {
      case 'es':
        return ''
      case 'en':
        return ''
      default:
        return '';
    }
  };

  // render
  return {
    getMetaContent
  }
}

export default UseMetaTags;