import React, { FC, useRef } from 'react';

import UseClickOutSide from '../../../../uses/useClickOutSide';

import Icon from '../../../Icon';
import Text from '../../../Typography/Text';

import { IBoardManagementItemModal } from './interfaces';

import './styles.scss';

// profile card modal
const BoardManagementItemModal: FC<IBoardManagementItemModal> = ({
  body,
  role,
  title,
  toggle
}) => {
  const element: any = useRef<any>();

  // click out
  UseClickOutSide(element, () => toggle());

  // render
  return (
    <div
      className="board-management-item-modal">
      <div
        className="board-management-item-modal--container"
        ref={element}>
        <div className="board-management-item-modal--title">
          <p className="paragraph title">{title}</p>
          {role && <p className="paragraph role">{role}</p>}

          <button
            className="btn" 
            onClick={toggle}>
            <Icon icon="icon-times" />
          </button>
        </div>

        <div className="board-management-item-modal--content">
          {body && <Text type="danger" text={body} />}
        </div>
      </div>
    </div>
  );
};

export default BoardManagementItemModal;