import React, { FC } from 'react';
import { Parallax } from "react-scroll-parallax";

import { IParallaxFixDiv } from './interfaces';

import './styles.scss';

// parallax fix div
const ParallaxFixDiv: FC<IParallaxFixDiv> = ({
  className = '',
  children,
  x,
  y,
}) => {
  // render
  return (
    <Parallax
      className={`parallax-fix ${className}`}
      translateX={x}
      translateY={y}>
      {children}
    </Parallax>
  );
};

export default ParallaxFixDiv;