import React, { FC } from 'react';

import HeaderMenuSubmenuItem from './HeaderMenuSubmenuItem';

import { IHeaderMenuItemData } from '../HeaderMenuItem/interfaces';
import { IHeaderMenuSubmenu } from './interfaces';

import './styles.scss';

// header menu submenu
const HeaderMenuSubmenu: FC<IHeaderMenuSubmenu> = ({
  submenu,
  lang,
  open,
  queries
}) => {

  // render
  return (
    <div
      className="header-menu-submenu"
      data-active={open}
      >
      {submenu && submenu.map((item: IHeaderMenuItemData, index: number) =>
        <HeaderMenuSubmenuItem
          lang={lang}
          item={item}
          key={index}
          dataKey={index} />)}
    </div>
  );
};

export default HeaderMenuSubmenu;