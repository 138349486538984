import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import Container from '../../layout/Container';
import ErrorIcon from '../../assets/icons/error-icon';

import { INotFound } from './interfaces';

import './styles.scss';

// not found
const NotFound: FC<INotFound> = ({
  lang
}) => {
  const { t } = useTranslation();

  // render
  return (
    <div className="not-found">
      <Container>
        <span className="not-found-title h2">
          {t('page_not_found.title')}
        </span>

        <p className="not-found-subtitle">
            <ErrorIcon />
            {t('page_not_found.subtitle')}
        </p>

        <div className="not-found-group">
          <span className="not-found-group-title h3">{t('page_not_found.list_title')}</span>

          <ul className="not-found-group-list">
            <li>
              <strong>{t('page_not_found.item_error_title')}</strong>
              <p>{t('page_not_found.item_error_description')}</p>
            </li>
            <li>
              <strong>{t('page_not_found.item_link_title')}</strong>
              <p>{t('page_not_found.item_link_description')}</p>
            </li>
            <li>
              <strong>{t('page_not_found.item_removed_title')}</strong>
              <p>{t('page_not_found.item_removed_description')}</p>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default NotFound;