import React, { FC } from 'react';

import ParallaxPageProvider from '../../providers/ParallaxPage';

import TimelineSlider from './TimelineSlider';

import { ITimeline } from './interfaces';

// time line
const Timeline: FC<ITimeline> = ({
  value
}) => {
  // render
  return (
    <ParallaxPageProvider>
      <TimelineSlider value={value} />
    </ParallaxPageProvider>
  );
};

export default Timeline;