import React, { FC, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import ReCAPTCHA from "react-google-recaptcha";

import InputForm from "../Input/InputForm";
import InputFormLabel from "../Input/InputForm/InputFormLabel";
import InputGroup from "../Input/InputGroup";
import FormSuccess from "./FormSuccess";
import Select from "../Select";

import useForm from "../../../uses/useForm";
import UseNavigation from "../../../uses/useNavigation";
import Container from "../../../layout/Container";

import { IFormContact } from "./interfaces";

import "./styles.scss";

// env
const { REACT_APP_BASE_API, REACT_APP_GOOGLE_CAPTCHA }: any = process.env;

// Componente de Loading
const LoadingOverlay: FC = () => (
  <div className="loading-overlay">
    <div className="spinner"></div>
  </div>
);

// form contact
const FormContact: FC<IFormContact> = ({ lang }) => {
  const recaptchaRef = useRef<any>(null);

  // state
  const [subjects, setSubject] = useState<any>([]);
  const [error, setError] = useState<boolean>(false);
  const [selectSubject, setSelectSubject]: any = useState<any>({
    value: "",
    label: "",
  });

  const { t }: any = useTranslation();
  const { getUrl }: any = UseNavigation();

  // params
  const location = useLocation();
  const url: string = getUrl(lang, REACT_APP_BASE_API);

  // use form
  const { typesForm } = useForm();

  // initial
  const initialValues: any = {
    recaptcha: "",
    name: "",
    phone: "",
    mail: "",
    subject: "",
    message: "",
    file_name: "",
    file_base64: "",
  };

  // select subject
  const onSetSubject = (value: string) => setSelectSubject(value);

  // options
  useEffect(() => {
    const api = async () => {
      const subject = await axios({
        method: "GET",
        url: `${url}/api/v2/formularios/fale-conosco/assuntos`,
      }).then(({ data }) => {
        if (data instanceof Object) {
          return data.map(({ id, name }: any) => ({ value: id, label: name }));
        }

        return [];
      });

      setSubject(subject);
    };

    api();
  }, [location, url]);

  // render
  return (
    <div className="form-contact" data-type="contact" data-error={error}>
      <Container type={2}>
        <div className="form-contact--container">
          <Formik
            // init
            initialValues={initialValues}
            initialStatus={{ success: false }}
            // validate
            validate={(values: any) => {
              const errors: any = typesForm(
                { ...values, selectSubject },
                "contact"
              );
              setError(Object.keys(errors).length > 0);

              return errors;
            }}
            // on submit
            onSubmit={(values, { setSubmitting, setStatus, resetForm }) => {
              setStatus({ success: false });

              const params = values;
              params.subject = selectSubject.value;
              params.recaptcha = recaptchaRef?.current?.getValue();

              setTimeout(() => {
                axios
                  .post(`${url}/api/v2/formularios/fale-conosco`, params)
                  .then(() => {
                    resetForm(initialValues);
                    setStatus({ success: true });
                  })
                  .catch(() => setStatus({ error: true }))
                  .finally(() => setSubmitting(false));
              }, 2000);
            }}
          >
            {({ values, errors, status, isSubmitting, setFieldValue }) => (
              <>
                {isSubmitting && <LoadingOverlay />}
                {(status.error === false || status.success === false) && (
                  <Form autoComplete="off" data-submitting={isSubmitting}>
                    <InputGroup>
                      <InputFormLabel
                        data-type="select"
                        data-error={errors.subject}
                      >
                        <Select
                          defaultText={t("form.select.selecione_um_assunto")}
                          options={subjects}
                          handleChange={onSetSubject}
                          selectedOption={selectSubject}
                        />

                        <input name="subject" type="hidden" required={true} />
                      </InputFormLabel>
                    </InputGroup>

                    <InputForm
                      label={t("form.label.name")}
                      name="name"
                      type="text"
                      error={errors.name}
                      empty={!values?.name}
                      required={true}
                    />

                    <InputForm
                      label={t("form.label.phone")}
                      name="phone"
                      type="tel"
                      error={errors.phone}
                      empty={!values?.phone}
                      required={true}
                    />

                    <InputForm
                      label={t("form.label.email")}
                      name="mail"
                      type="email"
                      error={errors.mail}
                      empty={!values?.mail}
                      required={true}
                    />

                    <div className="form-file">
                      <input
                        type="file"
                        id="fileUpload"
                        name="file_name"
                        accept=".pdf,.doc,.docx,.csv"
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          const typeError =
                            document.getElementById("fileTypeError");
                          const sizeError =
                            document.getElementById("fileSizeError");

                          // Oculta mensagens de erro inicialmente
                          typeError!.style.display = "none";
                          sizeError!.style.display = "none";

                          if (file) {
                            // Verifica o tamanho do arquivo (10MB)
                            if (file.size > 10 * 1024 * 1024) {
                              sizeError!.style.display = "block";
                              e.target.value = "";
                              return;
                            }

                            // Valida o tipo de arquivo
                            const validTypes = [
                              ".pdf",
                              ".doc",
                              ".docx",
                              ".csv",
                            ];
                            const fileType = file.name
                              .split(".")
                              .pop()
                              ?.toLowerCase();
                            if (!validTypes.includes(`.${fileType}`)) {
                              typeError!.style.display = "block";
                              e.target.value = "";
                              return;
                            }

                            // Transforma o arquivo em base64 e remove o prefixo
                            const reader = new FileReader();
                            reader.onload = () => {
                              const base64Data = reader.result as string;
                              const base64Content = base64Data.includes(",")
                                ? base64Data.split(",")[1]
                                : base64Data; // Remove o prefixo se existir

                              setFieldValue("file_base64", base64Content); // Apenas o conteúdo codificado
                              setFieldValue("file_name", file.name);
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                      />

                      {/* Mensagens de erro */}
                      <span
                        id="fileTypeError"
                        className="form-file-error"
                        style={{ display: "none", color: "red" }}
                      >
                        Apenas arquivos nos formatos .pdf, .doc, .docx e .csv
                        são permitidos.
                      </span>
                      <span
                        id="fileSizeError"
                        className="form-file-error"
                        style={{ display: "none", color: "red" }}
                      >
                        O arquivo deve ter no máximo 10 MB.
                      </span>
                    </div>

                    <strong className="textarea-title">
                      {t("form.title_message2")}
                    </strong>

                    <InputGroup>
                      <InputForm
                        empty={values?.message}
                        label={t("form.label.your_message")}
                        name="message"
                        type="textarea"
                        error={errors.message}
                        required={true}
                      />
                    </InputGroup>

                    <div className="form-checkbox">
                      <input type="checkbox" name="agreeToTerms" required />
                      <p>
                      Declaro que aceito que meus dados sejam armazenados para fins de contato.
                      A Novo Litoral tem compromisso com os direitos à proteção dos dados de seus usuários,
                      colaboradores e parceiros.
                      Reforça o compromisso de que seus dados serão tratados
                      e protegidos em conformidade com a Lei Geral de Proteção de Dados (LGPD)
                      - Lei nº 13.709/2018.
                      </p>
                    </div>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={REACT_APP_GOOGLE_CAPTCHA}
                      onChange={(value: any) =>
                        setFieldValue("recaptcha", value)
                      }
                    />

                    {!status.success && (
                      <div className="form-contact--footer">
                        <button
                          className="form-contact--button"
                          type="submit"
                          disabled={
                            isSubmitting ||
                            recaptchaRef?.current?.getValue() === ""
                          }
                        >
                          {t("form.send")}
                        </button>
                      </div>
                    )}
                  </Form>
                )}

                {status.success === true && <FormSuccess type="success" />}
                {status.error === true && <FormSuccess type="error" />}
              </>
            )}
          </Formik>
        </div>
      </Container>
    </div>
  );
};

export default FormContact;
