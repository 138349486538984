import React, { FC } from 'react';

import { IWebdoorControls } from './interfaces';

import './styles.scss';

// webdoor controls
const WebdoorControls: FC<IWebdoorControls> = ({
  current,
  items,
  setCurrent
}) => {
  // render
  return (
    <div
      className="webdoor-controls">
      {items.length > 1 && items.map((item: any, index: number) =>
        <button
          className={`btn webdoor-controls--btn ${index === current ? 'active' : ''}`}
          onClick={() => setCurrent(index)}
          key={index}>&nbsp;</button>)}
    </div>
  );
};

export default WebdoorControls;