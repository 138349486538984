import React, { FC, useCallback, useEffect, useState } from 'react';
import { parse, format } from 'date-fns';

import Text from '../../../Typography/Text';

import { INewsInfo } from './interfaces';

import './styles.scss';

// news item
const NewsInfo: FC<INewsInfo> = ({
  category,
  summary,
  date,
  lang
}) => {
  const dataFormat = parse(date, 'yyyy-MM-dd', new Date());
  const [limitedSummary, setLimitedSummary] = useState(summary);

  // use effect
  useEffect(() => {
    const charLimiter = () => {  
      if (summary) {
        if (summary.length > 175) {
          setLimitedSummary(summary.substring(0, 175) + "...")
        }
      }
    }
    
    charLimiter()
  }, [summary]);

  // get date
  const getDate = useCallback(() => {
    const value = lang !== 'en' ? format(dataFormat, 'dd/MM/yyyy') : format(dataFormat, 'MM/dd/yyyy');

    return value;
  }, [ dataFormat, lang ]);

  // render
  return (
    <div
      className="news-info">
      <div className="news-info--year">
        <p className="text">{getDate()}</p>
      </div>

      <div className="news-info--content">
        {/* {category && 
        <div className="news-info--category">{category}</div>
        } */}

        <div className="news-info--description">
          <Text attrs={{ className: 'description' }} type="danger" text={limitedSummary} />
        </div>
      </div>
    </div>
  );
};

export default NewsInfo;