import React, { FC } from 'react';

import Container from '../../../layout/Container';
import ImageTextSliderInfo from '../ImageTextSliderInfo';
import ImageTextSliderImage from '../ImageTextSliderImage';
import SliderBase from '../../Slider/Base';

import { IImageTextSliderContent } from './interfaces';

import './styles.scss';

// list business content
const ImageTextSliderContent: FC<IImageTextSliderContent> = ({
  lang,
  current,
  items,
  direction,
  last,
  onPrevNext,
  setCurrent,
}) => {
  return (
    <div
      className="image-text-slider--content">
      <Container>
        <ImageTextSliderInfo
          current={current}
          count={items.length}
          items={items}
          onPrevNext={onPrevNext}
          lang={lang}
        />
        
        <ImageTextSliderImage
          direction={direction}
          current={current}
          last={last}
          items={items}
          setCurrent={setCurrent} 
        />
      </Container>
    </div>
  );
};

export default SliderBase(ImageTextSliderContent);