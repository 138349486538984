import React, { FC } from "react";

import AccordionItem from "./Accordion";
import Container from "../../layout/Container";

import { IAccordionList } from "./interfaces";
import { IAccordion } from "./Accordion/interfaces";

import "./styles.scss";

// accordion
const AccordionList: FC<IAccordionList> = ({ children = false, value }) => {
  value.map((item) => item);
  // render
  return (
    <div className="accordion-list">
      <Container>
        {value &&
          value.map((item: IAccordion, index: number) => (
            <AccordionItem children={children} {...item} key={index} />
          ))}
      </Container>
    </div>
  );
};

export default AccordionList;