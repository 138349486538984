import React, { FC, useCallback } from 'react';

import Text from '../../Typography/Text';

import { LinkButton } from '../../LinkButton'

import { IImageText } from './interfaces';

import './styles.scss';

// image text item
const ImageText: FC<IImageText> = ({ 
  image,
  image_alt,
  tag,
  title,
  description,
  button_label,
  internal_link,
  external_link,
  type,
  lang
}) => {
  // factory link
  const factoryLink = useCallback((url: string) => {
    let src: string = url.replace(/.*\/\/[^/]*/, '');

    if (src[src.length-1] === '/') {
      src = src.slice(0, -1);
    }

    return `/${lang}${src}`;
  }, [ lang ]);

  // render
  return (
    <div
      className="image-text"
      data-type={type}>
      <div className="image-text--image">
        <img
          className="image"
          src={image?.original.src}
          alt={image_alt} />
      </div>

      <div className="image-text--info">
        {tag && <span>{tag}</span>}

        <strong>{title}</strong>
        
        <Text attrs={{ className: 'description' }} type="danger" text={description} />

        {((internal_link && external_link) || external_link) && <LinkButton
          href={external_link}
          rel="no-noreferrer"
          target="_blank"
          align={type === 1 ? "right" : "left"}
        >
          {button_label}
        </LinkButton>}

        {internal_link && !external_link && <LinkButton 
        href={factoryLink(internal_link?.url)}
        align={type === 1 ? "right" : "left"}
        >
          {button_label}
        </LinkButton>}
      </div>
    </div>
  );
};

export default ImageText;