import React, { FC } from "react";

import { IColumnItem } from "./interfaces";

import "./styles.scss";
import Text from "../../../Typography/Text";

const ColumnItem: FC<IColumnItem> = ({
  lang,
  dataColumn,
  title,
  body,
}) => {
  // render
  return (
    <li data-column={dataColumn}>
      {title && <span className="title">{title}</span>}

      {body && 
        <Text attrs={{ className: 'text' }} type="danger" text={body} />
      }
    </li>
  );
};

export default ColumnItem;