import { useTranslation } from 'react-i18next';
import React, { FC, useEffect, useRef } from 'react';

import Container from '../../../layout/Container';
import Text from '../../Typography/Text';
import TimelineItemSlider from './TimelineItemSlider';

import { ITimelineItem } from './interfaces';

import './styles.scss';

// time line item
const TimelineItem: FC<ITimelineItem> = ({
  active,
  dir,
  index,
  last,
  listsubitems,
  decade
}) => {
  const element = useRef<any>();
  const { t }: any = useTranslation();

  useEffect(() => {
    const getChildrenImages = () => {
      const activeVerification = () => {
        if (element.current.getAttribute("data-active") === "true") {
          const activeWrapper = element.current;
          const activeWrapperBackground = activeWrapper.querySelector(".time-line-item--bg");
          const activeWrapperInfo = activeWrapper.querySelector(".time-line-item--info");
          const sliderContainer = activeWrapperInfo.querySelector(".time-line-item-slider-container");
          const slides = sliderContainer.querySelectorAll(".time-line-item-slider-item");
  
          const config = { attributes: true, subtree: true };
  
          const activeVerification = () => {
            slides.forEach((element: any) => {
              if (element.getAttribute("data-active") === "true") {
                const itemImage = element.querySelector("div:has(img)");
    
                if (itemImage) {
                  const clone = itemImage.cloneNode(true);
                  clone.setAttribute("class", "time-line-item--image");
    
                  activeWrapperBackground.innerHTML = "";
                  activeWrapperBackground.appendChild(clone);
                }
                else {
                  activeWrapperBackground.innerHTML = "";
                }
              }
            });
          };
  
          const slideObserver = new MutationObserver(activeVerification);
  
          slideObserver.observe(sliderContainer, config);
  
          activeVerification();
        }
      }

      const config = { attributes: true, subtree: true };

      const itemObserver = new MutationObserver(activeVerification);
      
      itemObserver.observe(element.current, config);

      activeVerification();
    }

    getChildrenImages();
  }, []);

  // render
  return (
    <div
      className="time-line-item"
      data-init={index === 0}
      data-active={active}
      data-last={last}
      data-dir={active || last ? dir : ''}
      ref={element}>

      <div className="time-line-item--bg"></div>

      <div
        className="time-line-item--info"
        data-active={active}>
        <Container>
          <Text attrs={{ className: 'time-line-item--title' }}>
            <span className="label">{t('decade')}</span>
            <span className="date">{decade}</span>
          </Text>

          {listsubitems &&
            <TimelineItemSlider items={listsubitems} />}
        </Container>
      </div>
    </div>
  );
};

export default TimelineItem;