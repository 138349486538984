import React, { FC } from 'react';

import Container from '../../layout/Container';
import ImageText from './ImageText';

import { IImageTextList } from './interfaces';

import './styles.scss';

// image text
const ImageTextList: FC<IImageTextList> = ({
  value,
  lang
}) => {
  // render
  return (
    <div
      className="image-text-list">
      <Container>
        {value && value.map((item, index: number) => 
          <ImageText
            {...item}
            lang={lang}
            type={index % 2 === 0 ? 1 : 2}
            key={index} />)}
      </Container>
    </div>
  );
};

export default ImageTextList;