import React, { FC } from 'react';

import CardImageTitleTextItem from './CardImageTitleTextItem';

import { ICardImageTitleText } from './interfaces';

import './styles.scss';

const CardImageTitleText: FC<ICardImageTitleText> = ({ 
  value: {
    items,
  }
}) => {
  // render
  return (
    <div className="card-image-title-text">
      {items && items.map((item, index: number) => (
        <CardImageTitleTextItem
          key={index}
          image={item.value.image}
          image_alt={item.value.image_alt}
          title={item.value.title}
          description={item.value.description}
        />
      ))}
    </div>
  );
};

export default CardImageTitleText;
