import React, { Fragment, FC } from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { IInputForm } from './interfaces';

import './styles.scss';

// Input
const InputForm: FC<IInputForm> = ({
  maxLength,
  required,
  empty,
  name,
  label,
  info,
  type,
  error,
  value,
  checked,
  setFieldValue,
}) => {
  const { t }: any = useTranslation();

  // get input type
  const getInputType = () => {
    switch (type) {
      case 'check':
        return <Fragment>
          <Field
            name={name}
            type="checkbox" 
            required={required} />
          <label className="icon icon-check"></label>
        </Fragment>;
          
      case 'email':
        return <Field
          name={name}
          type="text" 
          required={required}
          value={value} />;

      case 'radio':
        return <Fragment>
          <input
            type="radio"
            id={name+value}
            name={name}
            checked={checked}
            onChange={() => setFieldValue(name, value)} 
            required={required} />
          <label className="radio-icon" htmlFor={name+value}></label>
        </Fragment>;

      case 'textarea':
        return <Fragment>
          <Field
            rows="12"
            name={name}
            component="textarea"
            required={required} />
          <label className="info">{info}</label>
        </Fragment>;

      case 'text':
      default:
        return <Field
          type="text"
          name={name}
          maxLength={maxLength}
          required={required}
          value={value} />;
    }
  };

  // render
  return (
    <label
      className="input-form"
      data-empty={!empty}
      data-error={error}
      data-type={type}>
      {getInputType()}
      <label className="label">
        <span className="required-input">*</span>
        {label}
      </label>
      {error && <label className="error">{t('form.label.error')}</label>}
    </label>
  );
};

export default InputForm;