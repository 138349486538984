import React, { FC, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';

import Container from '../../layout/Container';
import Text from '../Typography/Text';

import { ITitleText } from './interfaces';

import './styles.scss';
import UseNavigation from '../../uses/useNavigation';

// env
const { REACT_APP_BASE_API }: any = process.env;

// title
const TitleText: FC<ITitleText> = ({
  lang,
  value: {
    body,
    title
  }
}) => {
  const { getUrl } = UseNavigation();
  const url: string = getUrl(lang, REACT_APP_BASE_API);

  const element: any = useRef<any>(null);

  // link
  const linkFactory = useCallback((id: string) => {
    return axios({
      method: 'GET',
      url: `${url}/api/v2/pages/?id=${id}`
    }).then(({ data }) => {
      if (data.hasOwnProperty('items') && element.current) {
        const { items } = data;
        const link = element.current.querySelector(`a[id="${id}"]`);

        if (link && items[0]) {
          link.setAttribute('href', `/${lang}/${items[0].meta.slug}`);
        }

        return true;
      }
    });
  }, [ lang, element, url ]);

  // links docs
  const linksParser = useCallback(() => {
    if (!body) return '';

    const parser = new DOMParser();

    let content: any = parser.parseFromString(body, "text/html");
    const links = content.getElementsByTagName('a');

    Array.from(links).forEach((item: any) => {
      const type = item.getAttribute('linktype');
      const id: any = item.getAttribute('id');

      if (type === 'page') {
        linkFactory(id);
      }
    });

    return content.body.innerHTML;
  }, [ linkFactory, body ]);

  // use effect
  useEffect(() => {
    if (element.current instanceof Object) {
      linksParser();
    }

  // external link
  const setTargetBlank = () => {
    const paragraphs = document.querySelectorAll('.paragraph');

    paragraphs.forEach((paragraph) => {
      const external_link_list = paragraph.querySelectorAll('a[href^=http]');

      external_link_list.forEach((external_link) => {
        external_link.setAttribute('target', '_blank')
      })
    })
  }
  
  setTargetBlank()
  }, [ element, linksParser ]);

  // render
  return (
    <div
      className="title-text"
      ref={element}>
      <Container type={2}>
        {title && title?.indexOf('</h3>') !== -1
        ? 
          <p className='h3' dangerouslySetInnerHTML={{ __html: title }} />
        : title && title?.indexOf('</h4>') !== -1 
        ?
          <p className='h4' dangerouslySetInnerHTML={{ __html: title }} />
        :
        ''
        }

        {body && 
          <Text attrs={{ className: 'title-text--text' }} type="danger" text={body} />
        }
      </Container>
    </div>
  );
};

export default TitleText;