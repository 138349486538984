import React, { FC } from "react";

import Container from "../../layout/Container";
import RelatedContent from "./RelatedContent";

import { IRelatedContentList } from "./interfaces";

import "./styles.scss";

const RelatedContentList: FC<IRelatedContentList> = ({
  lang,
  value: {
    title,
    items
  }
}) => {
  // render
  return (
    <div className="related-content-list">
      {title && <h3 className="h3">{title}</h3>}

      <Container>
        {items && items.map((item, index: number) => (
            <RelatedContent {...item} lang={lang} key={index} />
          ))}
      </Container>
    </div>
  );
};

export default RelatedContentList;