import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import axios from 'axios';

import UseNavigation from '../../uses/useNavigation';

import { LangContext } from '../LangProvider';

import { INavContext, INavProvider } from './interfaces';

// env
const { REACT_APP_BASE_API }: any = process.env;

// page context
const NavContext = createContext({} as INavContext);

// page provider
const NavProvider: FC<INavProvider> = ({ children }) => {
  const { lang }: any = useContext(LangContext);
  const { getUrl, getRoutes } = UseNavigation();

  const server = getUrl(lang?.lang, REACT_APP_BASE_API);

  const [ data, setData ] = useState<any>([]);
  const [ error, setError ] = useState<any>(false);

  // const dataRef = useRef<any>({});
  // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  // effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${server}/api/v2/pages/?type=paginas.pagina&fields=navegacao,url_externa,retranca&limit=1000`
        ).then(({ data }: any) => data);
      
        setData(result);
        // dataRef.current = result;
        // forceUpdate()
      } catch (error) {
        setError(true);
      }
    };
    fetchData();
  }, [ server ]);

  // render
  return (
    <NavContext.Provider
      value={{
        routes: getRoutes(data?.items),
        data,
        error
      }}>
        {children}
    </NavContext.Provider>
  );
};

export { NavContext, NavProvider };
export default NavProvider;