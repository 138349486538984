import React, { FC } from 'react';

import NotFound from '../../components/NotFound';

import Footer from '../../layout/Footer';
import Header from '../../layout/Header';

import { IPageNotFound } from './interfaces';

// page not found
const PageNotFound: FC<IPageNotFound> = ({
  lang
}) => {
  // render
  return (
    <>
      <Header type={2} />

      <NotFound
        lang={lang} />

      <Footer /> 
    </>)
};

export default PageNotFound;