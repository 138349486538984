import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

// use box
const useBox = () => {
  const ref: any = useRef<any>(null);
  const [box, setBbox] = useState({});

  const set = () =>
    setBbox(ref && ref.current ? ref.current?.getBoundingClientRect() : {});

  useEffect(() => {
    set();
    window.addEventListener('resize', set);

    return () => window.removeEventListener('resize', set);
  }, []);

  return { box, ref };
};

export default useBox;