import React, { FC, useEffect, useState } from "react";
import { useMeasure } from "react-use";

import Text from "../../Typography/Text";

import { IAccordion } from "./interfaces";

import "./styles.scss";

// accordion item
const Accordion: FC<IAccordion> = ({ children = false, body, title }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [contentHeight, setContentHeight]: any = useState<number>(0);

  const [ref, { height }]: any = useMeasure();

  // use effect
  useEffect(() => {
    setContentHeight(height);

    window.addEventListener("resize", setContentHeight(height));
    return window.removeEventListener("resize", setContentHeight(height));
  }, [height]);

  // render
  return (
    <div className="accordion">
      <div className="accordion--title" data-active={open}>
        <button className="btn" onClick={() => setOpen(!open)}>
          <span className="paragraph title">{title}</span>
        </button>
      </div>

      <div
        className="accordion--body"
        data-active={open}
        style={{ height: open ? contentHeight : 0 }}
      >
        <div className="accordion--body--content" ref={ref}>
          {!children ? <Text type="danger" text={body} /> : `${body}`}
        </div>
      </div>
    </div>
  );
};

export default Accordion;