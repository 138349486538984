import React, { FC } from 'react';

import Container from '../../../layout/Container';
import TimelineControlsItem from './TimelineControlsItem';
import withMediaQueries from '../../MediaQueries';

import { ITimelineItem } from '../TimelineItem/interfaces';
import { ITimelineControls } from './interfaces';

import './styles.scss';

// time line controls
const TimelineControls: FC<ITimelineControls> = ({
  active,
  currentPage,
  items,
  queries,
  parent,
  size,
}) => {
  // const offSet = useMemo(() => size.width <= 1024 ? 55 : 147, [ size ]); // offset

  // render
  return (
    <div
      className="time-line-controls"
      data-active={active}
      >
      <Container>
        <div
          className="time-line-controls--container"
          style={{
            width: queries === 'xs' ? 75 * items.length : 'auto'
          }}>
          {items && items.map(({ decade }: ITimelineItem, index: number) =>
            <TimelineControlsItem
              active={index === (currentPage - 1)}
              decade={decade}
              parent={parent}
              index={index}
              key={index} />)}
        </div>
      </Container>
    </div>
  );
};

export default withMediaQueries(TimelineControls);