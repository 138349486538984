import React, { FC } from 'react';

import { ILinkButton } from './interfaces';

import './styles.scss';

// link border
export const LinkButton: FC<ILinkButton> = ({
  onClick,
  href,
  rel,
  target,
  align,
  children,
}) => {
  return (
    <a
      className="link-button"
      href={href}
      rel={rel}
      target={target}
      data-align={align}
      onClick={onClick}
    >
      {children}
    </a>
  );
};
