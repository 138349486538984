import React, { FC } from 'react';

import { ITimelineItemSliderButton } from './interfaces';

import './styles.scss';
import SliderArrow from '../../../../../assets/icons/slider-arrow';

// time line item slider button
const TimelineItemSliderButton: FC<ITimelineItemSliderButton> = ({
  type,
  onClick,
  current = 0,
  count = 0
}) => {
  return (
    <button
      className={`btn time-line-item-slider-button ${type}`}
      data-disabled={type === 'prev'
        ? current <= 0 : current >= (count - 1) || !onClick}
      onClick={onClick}>
      <SliderArrow />
    </button>
  );
};

export default TimelineItemSliderButton;