import React, { FC } from 'react';

import HighlightBannerWithIconItem from './HighlightBannerWithIconItem';

import { IHighlightBannerWithIcon } from './interfaces';

import './styles.scss';

const HighlightBannerWithIcon: FC<IHighlightBannerWithIcon> = ({
  value: {
    title,
    items,
  }
}) => {
  // render
  return (
    <div className="highlight-banner-with-icon">
      {title && <strong className="highlight-banner-with-icon-title">{title}</strong>}

      <div className="highlight-banner-with-icon-content">
        {items && items.map((item, index: number) => (
          <HighlightBannerWithIconItem
            key={index}
            icon={item.value.icon}
            icon_alt={item.value.icon_alt}
            highlight={item.value.highlight}
            description={item.value.description}
          />
        ))}
      </div>
    </div>
  );
};

export default HighlightBannerWithIcon;
