import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import HeaderTopMenu from '../HeaderTopMenu';
import Icon from '../../../../components/Icon';
import LangSelect from '../../../../components/LangSelect';
import Search from '../../../../components/Search';

import { IHeaderTopSubmenu } from './interfaces';
import { IHeaderMenuItemData } from '../../HeaderMenu/HeaderMenuItem/interfaces';

import './styles.scss';

// header top submenu
const HeaderTopSubmenu: FC<IHeaderTopSubmenu> = ({
  routes,
  queries,
  open,
  setOpen,
  lang,
}) => {
  const { t } = useTranslation();

  // render
  return (
    <div
      className="header-top-submenu">
      {(queries === 'lg' || queries === 'md') &&
        <>
          <HeaderTopMenu
            lang={lang}
            queries={queries}
            items={routes.filter((item: IHeaderMenuItemData) => item.navegacao === 'secundaria')} />

            <div className="header-top-submenu-contact">
              <span>Emergências / Informações: 0800 098 88 55</span>
            </div>
        </>}

      {(queries !== 'lg' && queries !== 'md') &&
        <button
          className="btn btn-open"
          data-open={open}
          onClick={() => setOpen(!open)}>
          <Icon icon="icon-hamburguer" />
          <Icon icon="icon-times" />
        </button>}
    </div>
  );
};

export default HeaderTopSubmenu;