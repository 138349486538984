import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ImageTextSliderControls from '../ImageTextSliderControls';
import { LinkButton } from '../../LinkButton'

import { IImageTextSliderInfo, IImageTextSliderInfoItem } from './interfaces';

import './styles.scss';

// list business info
const ImageTextSliderInfo: FC<IImageTextSliderInfo> = ({
  lang,
  current,
  count,
  items,
  onPrevNext,
}) => {
  const [ height, setHeight ] = useState<number>(0);
  const element: any = useRef(null);

  // height body
  const heightBody = useCallback((element: HTMLElement) => {
    if (element instanceof Object) {
      const items = element.querySelectorAll('div[data-active]');

      items.forEach((item: any) => {
        const props = item.getBoundingClientRect();
        const value = props.height + 160;
        
        if (value > height) {
          setHeight(value);
        }
      });
    }
  }, [ height ]);

  const { t }: any = useTranslation();

  // use effect
  useEffect(() => {
    if (element.current instanceof Object) {
      heightBody(element.current);
    }
  }, [ items, element, heightBody ]);

  // factory link
  const factoryLink = useCallback((url: string) => {
    let src: string = url.replace(/.*\/\/[^/]*/, '');

    if (src[src.length-1] === '/') {
      src = src.slice(0, -1);
    }

    return `/${lang}${src}`;
  }, [ lang ]);

  // render
  return (
    <div
      className="image-text-slider--info"
      ref={element}
    >
      {items && items.map((item: IImageTextSliderInfoItem, index: number) =>
        <div
          className="image-text-slider--info--item"
          data-active={index === current}
          key={index}>
          <p className="paragraph title">{item?.category}</p>
          <h3 className="h2 subtitle">{item?.title}</h3>
          <p className="paragraph description">{item?.description}</p>

          {((item?.internal_link && item?.external_link) || item?.external_link) && 
          <LinkButton
            href={item?.external_link}
            rel="no-noreferrer"
            target="_blank"
            align={"left"}
          >
            {t('more')}
          </LinkButton>
          }

          {item?.internal_link && !item?.external_link && 
          <LinkButton
            href={factoryLink(item?.internal_link?.url)}
            align={"left"}
          >
            {t('more')}
          </LinkButton>}
        </div>
      )}

      <ImageTextSliderControls
          current={current}
          count={count}
          onPrevNext={onPrevNext} />
    </div>
  );
};

export default ImageTextSliderInfo;