import { createPortal } from 'react-dom';
import React, { FC, useState } from 'react';

import BoardManagementItemModal from './BoardManagementItemModal';

import IconBio from '../../../assets/icons/bio';

import { IBoardManagementItem } from './interfaces';

import './styles.scss';

// profile card
const BoardManagementItem: FC<IBoardManagementItem> = ({
  body,
  image,
  title,
  role
}) => {
  const [ open, setOpen ] = useState<boolean>(false);
  const element: any = document.getElementById('search');

  // render
  return (
    <div
      className="board-management-item">
      <>
        <img
          className="image"
          src={image?.large.src}
          alt={title} />

        {body && 
        <button
          className="btn board-management-item--btn"
          onClick={() => setOpen(!open)}>
            <IconBio />
        </button>
        }

        <div className="board-management-item--info">
          <p className="title">{title}</p>
          <p className="role">{role}</p>
        </div>

        {open && createPortal(<BoardManagementItemModal
          title={title}
          role={role}
          body={body}
          toggle={() => setOpen(!open)} />, element)}
      </>
    </div>
  );
};

export default BoardManagementItem;