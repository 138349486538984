export const getSettings = (totalSlides: number) => {
  return {
    dots: totalSlides > 4,
    arrows: false,
    infinite: false,
    slidesToScroll: 4,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: totalSlides > 3
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: totalSlides > 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: totalSlides > 1
        }
      }
    ]
  };
};