import React, { FC, useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import { IRelatedContent } from "./interfaces";

import "./styles.scss";

const RelatedContent: FC<IRelatedContent> = ({
  lang,
  value: {
    external_link,
    internal_link,
    description
  }
}) => {
  // factory link
  const factoryLink = useCallback((url: string) => {
    const src: string = url.replace(/.*\/\/[^/]*/, '');

    return `/${lang}${src.slice(0, -1)}`;
  }, [ lang ]);

  // render
  return (
    <div className="related-content">
      {external_link && !internal_link 
      ? 
        <a href={external_link} className="related-content-link" target="_blank" />
      :
        <Link to={factoryLink(internal_link.url)} className="related-content-link" />
      }

      {description &&
        <p className="related-content-description">{description}</p>
      }
    </div>
  );
};

export default RelatedContent;