import React from 'react';
import { createRoot } from 'react-dom/client';
import { ParallaxProvider } from 'react-scroll-parallax';

import reportWebVitals from './reportWebVitals';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { BrowserRouter } from 'react-router-dom';

import MainProvider from './providers/MainProvider';
import NavProvider from './providers/NavProvider';
import LangProvider from './providers/LangProvider';

import Main from './layout/Main';

import './i18n';

import './styles/main.scss';

import {
  PortalProvider as OrigPortalProvider
} from 'react-portal-hook'

export const PortalProvider: React.FC<{ children: React.ReactNode }> = OrigPortalProvider

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <BrowserRouter basename="/">
        <ParallaxProvider>
          <PortalProvider>
            <LangProvider>
              <NavProvider>
                <MainProvider>
                  <Main />
                </MainProvider>
              </NavProvider>
            </LangProvider>
          </PortalProvider>
        </ParallaxProvider>
      </BrowserRouter>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

