import React, { FC } from "react";

import { IFile } from "./interfaces";

import "./styles.scss";

// list files item
const File: FC<IFile> = ({ title, file_item }) => {
  return (
    <div className="file">
      <a className="link" href={file_item.url} rel="noreferrer" target="_blank"></a>
      <p className="title">{title}</p>
    </div>
  );
};

export default File;
