import { FC } from 'react';

import { IPaginationButton } from './interfaces';

import SliderArrow from '../../../assets/icons/slider-arrow';

import './styles.scss';

// pagination button
const PaginationButton: FC<IPaginationButton> = ({
  attrs,
  onClick
}) => {
  // render
  return (
    <button
      {...attrs}
      className={`btn pagination-button ${attrs?.className}`}
      onClick={onClick}>
      <SliderArrow />
    </button>
  );
};

export default PaginationButton;