import React, { FC, useRef, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Icon from '../../../components/Icon';

import { IPageSearchInput } from './interfaces';

import './styles.scss';

// search input
const PageSearchInput: FC<IPageSearchInput> = ({
  lang,
  value,
  onSearch,
}) => {
  let history = useHistory();

  const { t }: any = useTranslation();
  const input: any = useRef<any>(null);

  // on click
  const onClick = useCallback((value: string) => {
    history.push(`/${lang?.lang}/search/${value}`);
  }, [ history, lang ]);

  // on enter
  const onKeyPress = useCallback((e: any) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      history.push(`/${lang?.lang}/search/${input.current.value}`);
      onSearch(input.current.value);
    }
  }, [ onSearch, lang, history ]);

  // use effect
  useEffect(() => {
    if (input.current instanceof Object) {
      input.current.value = value;
    }
  }, [ input, value ]);
  
  // render
  return (
    <div
      className="page-search-input">
      <input 
        className='input-search'
        type='text'
        placeholder={t('form.input.here')}
        onKeyDown={onKeyPress}
        ref={input} />

      <button
        className="btn btn-search"
        onClick={() => onClick(input.current.value)}>
        <Icon icon="icon-search" />
      </button>
    </div>
  );
};

export default PageSearchInput;