import React, { FC } from 'react';

import News from '../News';
import Pagination from '../../Pagination';

import { INewsListContent } from './interfaces';

import './styles.scss';

// news content
const NewsListContent: FC<INewsListContent> = ({
  data,
  lang,
  page,
  onNextPrev,
  perPage,
  prefix
}) => {
  // render
  return (
    <div
      className="news-list-content">
      <div
        className="news-list-content--container">
        {data?.items && data?.items.map((item: any, index: number) =>
          <News
            {...item}
            lang={lang}
            prefix={prefix}
            key={index} />)}
      </div>
      
      {data?.meta &&
        <Pagination
          current={page}
          total={Math.ceil(data?.meta.total_count / perPage)}
          setCurrent={onNextPrev} />}
    </div>
  );
};

export default NewsListContent;