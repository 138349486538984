//@ts-nocheck
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import HeaderMenuSubmenu from '../HeaderMenuSubmenu';
import Icon from '../../../../components/Icon';
import withMediaQueries from '../../../../components/MediaQueries';

import { IHeaderMenuItem } from './interfaces';

import './styles.scss';

// header menu item
const HeaderMenuItem: FC<IHeaderMenuItem> = ({
  current,
  index,
  item,
  lang,
  location,
  open,
  queries,
  setOpen,
  submenu,
  dataKey,
}) => {
  // render
  return (
    <div
      className="header-menu-item"
      data-key={dataKey}
      data-active-mobile={open}
      data-active={location.pathname === `/${item?.meta.slug}`}>
      {submenu === false && 
        <NavLink
          to={`/${lang}/${item?.meta.slug}`}
          className="header-menu-item--link">
          <span className="paragraph text">{item?.title}</span>
        </NavLink>}

      {submenu === true &&
        <>
          <button
            className="btn header-menu-item--link"
            data-active={current === index}
            onClick={() => setOpen(index)}>
              <NavLink
                to={item?.retranca ? `/${lang}/${item?.submenu[0].meta.slug}` : `/${lang}/${item?.meta.slug}`}>
                <span className="paragraph text">{item?.title}</span>
              </NavLink>

              {(queries !== 'lg' && queries !== 'md') &&
                <Icon icon="icon-arrow-right" />}
          </button>

          <HeaderMenuSubmenu
            lang={lang}
            open={open}
            queries={queries}
            submenu={item?.submenu} />
        </>}
    </div>
  );
};

export default withMediaQueries(HeaderMenuItem);