// video button icon
const VideoButton = () => {
    return (
        <button className="video-button">
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 20.4152V1.58479C0 0.433059 1.24422 -0.28898 2.24421 0.28243L18.7209 9.69766C19.7286 10.2735 19.7286 11.7266 18.7209 12.3023L2.24421 21.7175C1.24422 22.289 0 21.5669 0 20.4152Z" fill="#003A69"/>
            </svg>
        </button>
    );
  };
  
  export default VideoButton;