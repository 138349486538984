import axios from "axios";

import { parse, format } from "date-fns";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Text from "../../../components/Typography/Text";
import withPage from "../../../components/Page";

import Container from "../../../layout/Container";
import Footer from "../../../layout/Footer";
import Header from "../../../layout/Header";
import HeroImage from "../../../components/HeroImage";

import { IPageNews } from "./interfaces";

import "./styles.scss";
import { LinkButton } from "../../../components/LinkButton";
import Page from "../../../layout/Page";

// page new
const PageNews: FC<IPageNews> = ({ page, lang }) => {
  const { t }: any = useTranslation();

  const [parentData, setParentData]: any = useState();

  const dataFormat = parse(page?.date, "yyyy-MM-dd", new Date());

  // use effect
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios
          .get(`${page?.meta.parent.meta.detail_url}`)
          .then(({ data }: any) =>
            axios.get(`${data?.meta.parent.meta.detail_url}`)
          )
          .then(({ data }: any) => data);

        setParentData(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const parent = document.createElement("a");
  parent.href = `${parentData?.meta.html_url}`;

  const pathname = parent.pathname.split("/");
  pathname.pop();
  pathname[0] = `${lang.lang}`

  parent.host = window.location.host;
  parent.pathname = pathname.join("/");

  // render
  return (
    <div className="page-news">
      <Header type={2} />

      <HeroImage
        image={parentData?.imagem_do_header}
        title={parentData?.title} />

      {page?.meta && (
        <Container type={2}>
          <Text attrs={{ className: "date" }}>
            {lang.lang !== "en"
              ? format(dataFormat, "dd/MM/yy")
              : format(dataFormat, "MM/dd/yy")}
          </Text>

          {/* <div className="category">{page?.category}</div> */}

          <h3 className="title">{page?.title}</h3>

          <Page data={page} lang={lang?.lang} />

          <LinkButton
            href={parent.href}
            rel="noopener"
            align="center"
          >
            {t("see_news")}
          </LinkButton>
        </Container>
      )}

      <Footer lang={lang} />
    </div>
  );
};

export default withPage(PageNews);
