import React, { FC } from "react";

import ImageSideBySideItem from "./ImageSideBySideItem";

import { IImageSideBySide } from "./interfaces";

import "./styles.scss";

// image side by side
const ImageSideBySide: FC<IImageSideBySide> = ({
  value: { image1, image2 },
}) => {
  return (
    <div className="image-side-by-side">
      <ImageSideBySideItem {...image1} type={1} />

      <ImageSideBySideItem {...image2} type={2} />
    </div>
  );
};

export default ImageSideBySide;
