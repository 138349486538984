import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../../Icon';

import { IFormSuccess } from './interfaces';

import './styles.scss';

// form success
const FormSuccess: FC<IFormSuccess> = ({ type = 'success' }) => {
  const { t }: any = useTranslation();

  // render
  return (
    <div
      className="form-success"
      data-type={type}>
      <Icon icon={`icon-${type !== 'success' ? 'warning' : 'checked'}`} />
      <p className="title">{t(`form.${type}.title`)}</p>
      <p className="description">{t(`form.${type}.description`)}</p>
    </div>
  );
};

export default FormSuccess;