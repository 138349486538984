import React, { FC, useEffect } from 'react';

import TimelineItem from '../TimelineItem';

import { ITimelineItem } from '../TimelineItem/interfaces';
import { ITimelineContainer } from './interfaces';

import './styles.scss';

// time line container
const TimelineContainer: FC<ITimelineContainer> = ({
  current,
  dir,
  height,
  getLast,
  last,
  items,
  pageIndex,
  wrapper,
}) => {
  
  useEffect(() => {
    getLast(current);
  }, [ current, getLast ]);

  // render
  return (
    <div
      className="time-line-container"
      ref={wrapper}
      style={{ height }}>
      <div className="stickyroll-content">
        {items && items.map((item: ITimelineItem, index: number) =>
          <TimelineItem
            {...item}
            dir={dir}
            active={pageIndex === index}
            last={(last - 1) === index}
            index={index}
            key={index} />)}
      </div>
    </div>
  );
};

export default TimelineContainer;