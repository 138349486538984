//@ts-nocheck
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import NewsInfo from './NewsInfo';

import { INews } from './interfaces';

import './styles.scss';

// news item
const News: FC<INews> = ({
 meta,
 image,
 lang,
 prefix,
 title,
 category,
 date,
 summary
}) => {
  // render
  return (
    <Link
      className="news"
      to={`/${lang}/${prefix}/${meta.slug}`}>
      <div className="news--image">
        <img
          alt={title}
          src={image?.meta.download_url || `${process.env.PUBLIC_URL}/images/placeholder-news.png`} />
      </div>

      <NewsInfo
        category={category}
        summary={summary}
        date={date}
        lang={lang} />
    </Link>
  );
};

export default News;