import React, { FC } from 'react';

import SliderBase from '../../../Slider/Base';
import TimelineItemSliderContainer from './TimelineItemSliderContainer';
import TimelineItemSliderControls from './TimelineItemSliderControls';
import TimelineItemSliderCurrent from './TimelineItemSliderCurrent';

import { ITimelineItemSlider } from './interfaces';

import './styles.scss';

// time line item slider
const TimelineItemSlider: FC<ITimelineItemSlider> = ({
  current,
  direction,
  last,
  items,
  onPrevNext,
  setCurrent
}) => {

  // render
  return (
    <div
      className="time-line-item-slider">
      <TimelineItemSliderContainer
        current={current}
        direction={direction}
        last={last}
        items={items}
        setCurrent={setCurrent} />

      <TimelineItemSliderCurrent
        current={current + 1}
        total={items.length} />

      <TimelineItemSliderControls
        current={current}
        count={items.length}
        onPrevNext={onPrevNext} />
    </div>
  );
};

export default SliderBase(TimelineItemSlider);