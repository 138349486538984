// sirius logo
const SiriusLogo = () => {
  return (
    <svg width="46" height="24" viewBox="0 0 46 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <path d="M8.3874 11.7205C7.89714 11.814 7.39106 12.0385 6.99165 12.4458C6.57687 12.8688 6.3311 13.4355 6.30509 14.0955C3.1205 13.2161 1 10.7868 1 8.17916C1 4.91537 4.35329 1.92114 8.91079 1.92114C13.4635 1.92114 16.8243 4.91247 16.8508 8.18265C16.8505 8.34756 16.8321 8.52163 16.8049 8.75255C16.6261 9.56035 15.9577 11.5108 13.7891 11.8339C12.3511 12.046 11.4569 11.9467 10.7553 11.8323C10.6597 11.8167 10.561 11.799 10.4593 11.7808C9.86157 11.6737 9.16172 11.5484 8.3874 11.7205Z" stroke="white" strokeWidth="2" />
        <path d="M42.5789 23.0797C41.0881 23.0797 39.9739 22.5147 39.2049 21.8556L40.1936 20.506C40.8056 21.0396 41.5746 21.4005 42.5319 21.4005C43.3479 21.4005 43.9442 21.1337 43.9442 20.6473C43.9442 20.1137 43.2537 19.9254 42.3749 19.7214C41.1038 19.4389 39.456 19.0466 39.456 17.2262C39.456 15.5313 40.9469 14.6996 42.7202 14.6996C44.0855 14.6996 45.1055 15.1861 45.8274 15.751L44.8387 17.1163C44.2581 16.6769 43.5833 16.3788 42.7672 16.3788C42.0768 16.3788 41.5118 16.5985 41.5118 17.0692C41.5118 17.6028 42.1866 17.7754 43.0811 17.9637C44.3522 18.2462 46 18.6385 46 20.4589C46 22.1538 44.4935 23.0797 42.5789 23.0797Z" fill="white" />
        <path d="M34.7327 23.0797C32.4729 23.0797 30.9977 21.7458 30.9977 19.7057V14.8879H33.0378V19.4232C33.0378 20.4432 33.7126 21.1024 34.7327 21.1024C35.7527 21.1024 36.4275 20.4432 36.4275 19.4232V14.8879H38.4676V19.7057C38.4676 21.7458 36.9768 23.0797 34.7327 23.0797Z" fill="white" />
        <path d="M27.6095 22.8914V14.8879H29.6496V22.8914H27.6095Z" fill="white" />
        <path d="M26.5424 14.7624H26.8876V16.8652H26.5424C25.0829 16.8652 24.2355 17.7284 24.2355 19.0936V22.8914H22.1954V14.8879H24.2355V15.9237C24.8162 15.1861 25.6008 14.7624 26.5424 14.7624Z" fill="white" />
        <path d="M18.6817 22.8914V14.8879H20.7218V22.8914H18.6817Z" fill="white" />
        <path d="M14.3591 23.0797C12.8683 23.0797 11.7541 22.5147 10.9851 21.8556L11.9738 20.506C12.5858 21.0396 13.3548 21.4005 14.312 21.4005C15.1281 21.4005 15.7244 21.1337 15.7244 20.6473C15.7244 20.1137 15.0339 19.9254 14.1551 19.7214C12.884 19.4389 11.2362 19.0466 11.2362 17.2262C11.2362 15.5313 12.727 14.6996 14.5003 14.6996C15.8656 14.6996 16.8857 15.1861 17.6076 15.751L16.6189 17.1163C16.0383 16.6769 15.3635 16.3788 14.5474 16.3788C13.8569 16.3788 13.292 16.5985 13.292 17.0692C13.292 17.6028 13.9668 17.7754 14.8613 17.9637C16.1324 18.2462 17.7802 18.6385 17.7802 20.4589C17.7802 22.1538 16.2737 23.0797 14.3591 23.0797Z" fill="white" />
        <path d="M18.6056 12.9851C18.6056 13.5978 19.0792 14.064 19.6845 14.064C20.2897 14.064 20.7634 13.5978 20.7634 12.9851C20.7634 12.3724 20.2897 11.9062 19.6845 11.9062C19.0792 11.9062 18.6056 12.3724 18.6056 12.9851Z" fill="white" />
        <path d="M27.531 12.9851C27.531 13.5978 28.0046 14.064 28.6099 14.064C29.2151 14.064 29.6887 13.5978 29.6887 12.9851C29.6887 12.3724 29.2151 11.9062 28.6099 11.9062C28.0046 11.9062 27.531 12.3724 27.531 12.9851Z" fill="white" />
      </g>
    </svg>

  );
};

export default SiriusLogo;