import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import Footer from '../../../layout/Footer';
import Header from '../../../layout/Header';
import HeroImage from '../../../components/HeroImage';
import Page from '../../../layout/Page';

import { IPageContainer } from './interfaces';

// page container
const PageContainer: FC<IPageContainer> = ({ 
  lang,
  status,
  page
}) => {
  let history = useHistory()

  // use effect
  useEffect(() => {
    if (status === 200) {
      if (page?.meta?.total_count === 0) {
        history.push(`/`);
      }
    }
  }, [ page, status, history, lang ]);

  // render
  return (
    <>
      <Header type={2} />

      <HeroImage
        image={page?.imagem_do_header}
        title={page?.title} />

      <Page
        data={page}
        lang={lang?.lang} />

      <Footer lang={lang} /> 
    </>
  );
};

export default PageContainer;