// error icon
const ErrorIcon = () => {
  return (
    <svg width="46" height="41" viewBox="0 0 46 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.68904 41H38.12C40.7365 41 43.0686 39.6409 44.3768 37.4323C45.6851 35.1671 45.6851 32.5055 44.4337 30.2403L29.1898 3.62431C27.8815 1.35912 25.5494 0 22.8761 0C20.2027 0 17.8706 1.35912 16.5624 3.62431L1.31844 30.2403C0.010196 32.5055 0.0670765 35.1671 1.37532 37.4323C2.74045 39.6975 5.07254 41 7.68904 41ZM4.9019 32.2224L20.1458 5.60635C20.7146 4.58702 21.7953 3.96409 22.9898 3.96409C24.1843 3.96409 25.2082 4.58702 25.8338 5.60635L41.0778 32.2224C41.6466 33.2417 41.6466 34.4309 41.0778 35.4503C40.509 36.4696 39.4282 37.0359 38.2906 37.0359H7.68904C6.49455 37.0359 5.4707 36.413 4.9019 35.4503C4.3331 34.4309 4.27622 33.2417 4.9019 32.2224Z" fill="#DA4252"/>
      <path d="M22.6486 27.0124C23.7293 27.0124 24.6394 26.1064 24.6394 25.0304V14.2707C24.6394 13.1948 23.7293 12.2887 22.6486 12.2887C21.5678 12.2887 20.6577 13.1948 20.6577 14.2707V25.0304C20.6577 26.1064 21.5678 27.0124 22.6486 27.0124Z" fill="#DA4252"/>
      <path d="M22.6486 34.3743C23.7293 34.3743 24.6394 33.4682 24.6394 32.3923V30.6934C24.6394 29.6174 23.7293 28.7113 22.6486 28.7113C21.5678 28.7113 20.6577 29.6174 20.6577 30.6934V32.3923C20.6577 33.4682 21.5678 34.3743 22.6486 34.3743Z" fill="#DA4252"/>
    </svg>
  );
};

export default ErrorIcon;