import React, { FC } from 'react';

import ImageTextSliderControlsButton from './ImageTextSliderControlsButton';

import { IImageTextSliderControls } from './interfaces';

import './styles.scss';

// list business controls
const ImageTextSliderControls: FC<IImageTextSliderControls> = ({
  current,
  count,
  onPrevNext
}) => {
  return (
    <div
      className="image-text-slider--controls ">
      <ImageTextSliderControlsButton
        disabled={current === 0}
        type="prev"
        onClick={onPrevNext} />

      <ImageTextSliderControlsButton
        disabled={current >= (count - 1)}
        type="next"
        onClick={onPrevNext} />
    </div>
  );
};

export default ImageTextSliderControls;