import React, { FC } from 'react';

import { IHighlightBannerWithIconItem } from './interfaces';

import Text from '../../Typography/Text';

import './styles.scss';

// card business item
const HighlightBannerWithIconItem: FC<IHighlightBannerWithIconItem> = ({
  icon,
  icon_alt,
  highlight,
  description
}) => {
  return (
    <div
      className="highlight-banner-with-icon-content-item">
      <img
        className="image"
        src={icon?.original.src}
        alt={icon_alt} />
      
      <strong>{highlight}</strong>

      <Text type="danger" text={description} />
    </div>
  );
};

export default HighlightBannerWithIconItem;