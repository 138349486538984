import axios from 'axios';
import React from 'react';
import useSWR from 'swr';

// fetcher
const fetcher = (url: string) => axios.get(url).then(({ data }: any) => data);

// load component data
const withPage: any = (ComponentElement: any) => {
  // render
  return (props: any) => {
    // const { id, url }: any = props;
    // const id: any = props.page.id;
    // const url: any = props.page.meta.html_url
    // console.log(props)
    const { data }: any = useSWR(`${props.page.meta.detail_url}`, fetcher);

    // render
    return(<ComponentElement
      data={data}
      {...props} />);
  };
};

export default withPage;