import { useState, useEffect } from 'react';

// use window size
const UseWindowSize = () => {
  const [ windowSize, setWindowSize ] = useState<{width: number; height: number}>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default UseWindowSize;